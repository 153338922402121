import tippy from "tippy.js";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

export default {
  created(el: any, bindings: any) {
    if (!bindings.value) {
      return;
    }

    el.style.cursor = "pointer";

    tippy(el, {
      content: `<div class="text-center" style="max-width: 400px">${bindings.value}</div>`,
      allowHTML: true,
      appendTo: () => document.body,
      delay: [400, 100],
      animation: "scale",
      trigger: bindings.modifiers.click ? "mouseenter click" : "mouseenter",
    });
  },
};
