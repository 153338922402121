export default {
  global: {
    contacts: {
      ticket_office: "Bilete avia:",
      accounting: "Contabilitate:",
      booking_department: "Rezervari:",
      office_phone: "Tel. birou:",
      mobile_phone: "Tel. mobil:",
    },
    hotel_details: {
      title: "Detalii hotel",
      labels: {
        contacts: "Contacte",
        address: "Adresa",
        phones: "Telefon",
        emails: "E-mail",
        hygiene: "Igienă",
        urls: "URL-uri",
        sports_entertainment: "Sport",
        meals: "Alimente",
        room_facilities: "Cazare",
        facilities: "Camerele",
        payment: "Plată",
        building_info: "Informații despre obiect",
        category: "Categorie",
        object_information: "Detalii de contact",
        extra_fee: "contra plată",
      },
    },
    baggage_info: {
      adult_title: "Adulți sau copii cu vârsta peste 12 ani",
      inf_title: "Infanți:",
      max_luggage: "Bagaj gratuit (max):",
      max_handle_luggage: "Bagaj de mână (max):",
      size: "Dimensiune:",
      kg: "kg",
      no: "nu",
      registration_message:
        "Înregistrarea se deschide cu {open} ore înainte și se închide cu {close} minute înainte de plecare.",
      details: "Detalii",
    },
    price_decoding: {
      hotel: "Hotel",
      daily_price: "Preturi zilnice",
      discounts: "Reduceri",
      tickets: "Bilete",
      mark_up: "Firma",
      sum: "Sumă",
      transfer: "Transfer",
      insurance: "Asigurare",
      neverland: "Neverland",
      commission: "Comision",
      agency_commission: "Comisionul agentiei",
      total: "Total brut",
      aquapark_services: "Aquapark entry",
      never_land_entrance: "Neverland Night Show",
      handling_fee: "Handling Fee",
      gala_dinner_extra: "Gala dinner",
      net_package_cost: "Prețul net",
      there: "Înсolo",
      back: "Înapoi",
      services_amount: "Suma serviciilor",
      total_net_amount: "Total net",
      total_tickets: "Total bilete",
      hotel_mark_up: "Mark up",
      taxes: "Taxe",
    },
    menu: {
      news: "Noutăți",
      tour: "Căutare pachete",
      orders: "Lista rezervărilor",
      plane: "Căutare bilete avion",
      employees: "Conturile agenției",
      bonuses: "Jurnalul bonusurilor",
      docs: "Dcoumente",
      rules: "Condiții generale",
      faq: "Manual de utilizare",
      subpartitions: "Subblocuri",
      agencies: "Managementul agentiei",
      flights: "Lista aviabilete",
      user_links: "Utilizatori conectați",
      ats_reports: "Raport de vânzări",
      payment_gateways: "Gateway-uri de plată",
      ats: {
        orders: "Lista aviabilete",
        sales_report: "Utilizatori conectați",
        user_links: "Utilizatori conectați",
        orders_payments: "Plăți",
      },
    },
    currency: {
      empty: "Cursul pentru ziua curentă nu este setat",
      ratio: "Raport: {ratio} EUR/USD de jos",
      EUR: "€",
      USD: "$",
    },
    measure_units: {
      percent: "%",
      EUR: "€",
      USD: "$",
    },
    is_regular_flight:
      "Tariful este nerambursabil. Pachetul include segmente pe un zbor regulat. Nu sunt acceptate modificări ale datelor turului, ajustări ale datelor pașapoartelor turiștilor (nume, prenume, data nașterii) după confirmare.",
    is_regular_flight_short: "Nerambursabil",
    menu_search: "Căutare",
    Home: "Pagina principală",
    not_selected: "Nu este ales nimic",
    select_date: "Selectați data",
    "no-results": "Ne pare rău, nu există variante de plecare posibile.",
    all: "Toate",
    clear_all: "Elimină tot",
    reset: "Resetare",
    search: "Căutare",
    from: "de la",
    enter: "Introduceți",
    to: "pînă la",
    age: "ani (infant) | an (infant) | ani | ani",
    Phone: "Telefon",
    Name: "Prenume",
    full_name: "Nume și Prenume",
    addContact: "Adaugă contact",
    attention: "Atenție!",
    select: "Selectați",
    selected: "Selectate",
    selected_count: "Selectat {count} din {total}",
    noOptionsText: "Listă goală",
    comments: "Comentariu",
    type_comment: "Introduceți comentariu",
    exist: "Disponibile",
    no: "Epuizat",
    show_all: "Arata tot",
    form: {
      ph_last_name: "Introduceți numele",
      ph_first_name: "Introduceți prenumele",
      ph_date: "Selectați data",
      ph_number: "Introduceți numărul de telefon",
      ph_fiscal_code: "CNP",
      title: "Nume companiei",
      phone: "Introduceți numărul de telefon",
      email: "Introduceți email",
      address: "Adresa companiei",
    },
    copy: "Copiați",
    clipboard_copy: "Copiați în clipboard",
    clipboard_copy_success: "Textul a fost copiat",
    clipboard_link_copy_success: "Linkul a fost copiat cu succes",
    table: {
      result_length: "Total rezultate:",
      per_page: "Arata",
      filters: "Filtre",
      no_results: "Ne pare rău, nu există opțiuni potrivite.",
      date: "Data",
      success_create_message: "Înregistrare a fost adăugată cu succes",
      success_edit_message: "Înregistrare a fost editata cu succes",
      success_delete_message: "Înregistrare a fost ștearsă cu succes",
    },
    labels: {
      from: "De la",
      to: "Pâna la",
      min_price: "Preț minim",
      max_price: "Preț maxim",
      average_price: "Preț mediu",
      price: "Preț",
      search_by_results: "Caută după rezultate",
      departure: "Plecare",
      arrival: "Sosire",
      copy: "Copiați",
      copy_row: "Copiați rândul",
    },
    buttons: {
      profile: "Profil",
      logout: "Ieșire",
      reset: "Resetare",
      save: "Salvare",
      sort: "Sortare",
      by_default: "Sortare implicita",
      cancel: "Anulare",
      search: "Caută",
      print: "Imprimă",
      confirm: "Confirm",
      clear: "Șterge",
      update: "Actualizare",
      add: "Adăuga",
      save_to_pdf: "Salvare în PDF",
      reset_contacts: "Contacte implicite",
      import_from_file: "Încărcați din fișier",
      load: "Încarcă",
    },
    print_error: "Imprimarea acestei pagini nu este disponibilă",
    flight_there: "Plecare",
    flight_back: "Întoarcere",
    flight: "Zbor",
    in_flight: "Durată",
    saved_offer: {
      title: "Oferta personala",
      accommodation_dates: "Perioada cazării",
      nutrition: "Masa",
      room_type: "Camera",
      accommodation: "Cazarea",
      name: "denumirea",
      nights: "{nights} noapte | {nights} nopți | {nights} nopți | {nights} nopți",
    },
    rules: {
      min_date: "Data trebuie să fie mai mare decât {date}",
      max_date: "Data trebuie să fie mai mică decât {date}",
      decimal: "Câmpul trebuie să fie un număr",
    },
    book: {
      flight_info: "Inclus: un bagaj de cală max. 20 kg și bagaj de mână max. 6 kg/persoană",
    },
    messages: {
      delete: "Ștergere",
      delete_answer: "Sunteți sigur că doriți să ștergeți înregistrarea selectată?",
      delete_all_answer: "Sunteți sigur că doriți să ștergeți toate înregistrarile selectate?",
      empty_list: "Nu au fost găsite înregistrări care să se potrivească",
      required_fields: "Campurile marcate cu asterix (*) sunt obligatorii.",
    },
    weekdays: {
      mon: "Luni",
      tue: "Marți",
      wed: "Miercuri",
      thu: "Joi",
      fri: "Vineri",
      sat: "Sâmbătă",
      sun: "Duminică",
    },
  },
};
